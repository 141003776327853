<template>
  <div class="card p-2">
    <div class="col-12 col-lg-12 col-xxl-12">
      <div class="row">
        <div class="col-12 col-sm-6 col-md-5">
          <div class="mb-1 row">
            <label for="colFormLabel" class="col-3 col-sm-3 col-lg-12 col-xl-3 col-form-label">
              Module
            </label>
            <div class="col-9 col-sm-9 col-lg-12 col-xl-9">
              <v-select
                  placeholder="Select Module"
                  v-model="moduleId"
                  :options="modules"
                  label="name"
                  :reduce="name => name.id"
              />
            </div>
          </div>
        </div>
        <div class="col-md-2 d-flex align-items-end">
          <button
              style="min-width: 64px;"
              @click=" getMenu"
              class="btn btn-primary btn-block waves-effect waves-float waves-light mb-1">
            Go
          </button>
        </div>
        <div class="col-md-5">
          <button
              @click="refreshMenu()"
              class="btn btn-success ms-2 btn-block waves-effect waves-float waves-light mb-1">
            Refresh
          </button>
          <router-link
              class="btn btn-secondary float-lg-end float-end mb-1 ms-2"
              :to="{name: 'MenuSorting', params:{moduleId: moduleId, companyId:companyId} }">
            Menu Sorting
          </router-link>
        </div>
      </div>
    </div>


    <MenuDesignTable
        @onClickAction="handleMenu"
        :menus="menuModified"
    />
    <Loader v-if="loading"/>
  </div>
</template>

<script>
import MenuDesignTable from '@/components/molecule/company/company-setting/edit/MenuDesignTable'
import Loader from '@/components/atom/LoaderComponent'
import {mapGetters, useStore} from 'vuex'
import handleCompany from '@/services/modules/company'
import { computed, inject } from 'vue'

export default {
   name: 'MenuDesign',

   components: {
       MenuDesignTable,
       Loader
   },

   data: () => ({
       menuOriginal: {},
       menuModified: {},
       companies: [],
       moduleId: null,
   }),

   computed: {
       ...mapGetters({
           modules: 'company/modules'
       }),

       companyId () {
           return this.$route.params.companyId
       }
   },

   methods: {
     async refreshMenu() {
       let query = `?company_id=${this.companyId}&module_id=${this.moduleId}`
       try {
         this.loading = true
         let res = await this.compareWithUpdateMenu(query)
         if(!res.status) {
           this.showError(res.message)
         }
         if(res.status) {
           this.showSuccess(res.message)
         }
       } catch (err) {
         if(!err.response) {
           this.showError('Something is wrong. Check your connectivity!!')
         }
         if(err.response) {
           this.showError(err.response.message)
         }
       } finally {
         this.loading = false
       }
     },
       async getMenu() {
           let query = `?company_id=${this.companyId}&module_id=${this.moduleId}`
           try {
                this.loading = true
                let res = await this.fetchMenu(query)
                if(!res.status) {
                    this.showError(res.message)
                }
                if(res.status) {
                    this.menuOriginal = res.data
                    this.prepareMenuForRender()
                }
            } catch (err) {
                if(!err.response) {
                    this.showError('Something is wrong. Check your connectivity!!')
                }
                if(err.response) {
                    this.showError(err.response.message)
                }
            } finally {
                this.loading = false
            }
       },

       async updateMenuSchema(data) {
           try {
                this.loading = true
                let res = await this.updateMenu(data)
                if(!res.status) {
                    this.showError(res.message)
                }
                if(res.status) {
                    this.showSuccess(res.message)
                    this.getMenu()
                }
            } catch (err) {
                if(!err.response) {
                    this.showError('Something is wrong. Check your connectivity!!')
                }
                if(err.response) {
                    this.showError(err.response.message)
                }
            } finally {
                this.loading = false
            }
       },

       prepareMenuForRender() {
           this.menuModified = JSON.parse(JSON.stringify(this.menuOriginal))
           this.menuModified.default_menu.map(defaultMenu => {
                let companyMenu = this.menuModified.company_menu.find(cm => cm.id == defaultMenu.id)
                if(!companyMenu) {
                    defaultMenu.notIncluded = true
                    this.menuModified.company_menu.push(defaultMenu)
                }

                if(companyMenu) {
                    defaultMenu.submenus.map(defaultSubmenu => {
                        let companySubmenu = companyMenu.submenus.find(csm => csm.id == defaultSubmenu.id)
                        if(!companySubmenu) {
                            defaultSubmenu.notIncluded = true
                            companyMenu.submenus.push(defaultSubmenu)
                        }
                        if(companySubmenu) {
                            defaultSubmenu.pages.map(defaultPage => {
                                let companyPage = companySubmenu.pages.find(cp => cp.id == defaultPage.id)
                                if(!companyPage) {
                                    defaultPage.notIncluded = true
                                    companySubmenu.pages.push(defaultPage)
                                }
                            })
                        }
                    })
                }
           })
       },

        handleMenu(data) {
            const  menu = JSON.parse(JSON.stringify(this.menuOriginal))

            let payload = {
                company_id: this.companyId,
                module_id: this.moduleId,
                menu_schema: null
            }

            if(data.actionType == 'Add') {
                delete data.item.notIncluded
                if(data.objType == 'menu') {
                    menu.company_menu.push(data.item)
                }
                if(data.objType == 'subMenu') {
                    menu.company_menu[data.index]
                        .submenus
                            .push(data.item)
                }
                if(data.objType == 'page') {
                    menu.company_menu[data.index]
                        .submenus[data.subMenuIndex]
                            .pages
                                .push(data.item)
                }
            }

            if(data.actionType == 'Remove') {
                if(data.objType == 'menu') {
                    menu.company_menu.splice(data.index, 1)
                }
                if(data.objType == 'subMenu') {
                    menu.company_menu[data.index]
                        .submenus.splice(data.subMenuIndex, 1)
                }
                if(data.objType == 'page') {
                    menu.company_menu[data.index]
                        .submenus[data.subMenuIndex]
                            .pages
                                .splice(data.pageIndex, 1)
                }
            }
            payload.menu_schema = JSON.stringify(menu.company_menu)
            this.updateMenuSchema(payload)
        }
   },

    setup () {
        const showError =  inject('showError');
        const showSuccess =  inject('showSuccess');
        const store = useStore()

        const {
            fetchMenu,
            updateMenu,
            compareWithUpdateMenu,
            loading
        } = handleCompany()

        return {
            modules: computed(() => store.state.company.modules),
            fetchMenu,
            updateMenu,
            compareWithUpdateMenu,
            loading,
            showError,
            showSuccess
        }
    },

    created() {
        this.moduleId = parseInt(this.$route.params.moduleId)
        this.getMenu()
    }
}
</script>
