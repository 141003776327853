<template>
    <div class=" p-2" style="min-height: 200px">
        <div class="table-responsive border-b border-l border-r">
            <table class="table">
                <thead>
                    <tr>
                        <th> Menu Design </th>
                        <th></th>
                        <th></th>
                        <!-- <th></th> -->
                        <th>Action</th>
                    </tr>
                </thead>
                
                <tbody>
                    <template v-for="(menu, i) in menus.company_menu" :key="i">
                        <tr>
                            <td> {{ menu.name }} </td>
                            <td>  </td>
                            <td>  </td>
                            <!-- <td > {{menu.status}} {{  }}</td> -->
                            <td>
                                <button
                                    @click="onClickAction({
                                        actionType: menu.notIncluded ? 'Add' : 'Remove',
                                        objType: 'menu',
                                        menuId: menu.id,
                                        item: menu,
                                        index: i
                                    })"
                                    :class="{'btn-success' : menu.notIncluded, 'btn-danger' : !menu.notIncluded}"
                                    class="btn w-100"
                                >
                                    {{ menu.notIncluded ? 'Add' : 'Remove' }}
                                </button>
                            </td>
                        </tr>

                        <template v-for="(subMenu, j) in menu.submenus" :key="j">
                            <tr v-if="!menu.notIncluded">
                                <td>  </td>
                                <td> {{ subMenu.name }} </td>
                                <td>  </td>
                                <!-- <td > {{subMenu.status}} </td> -->
                                <td>
                                    <button
                                        @click="onClickAction({
                                            actionType: subMenu.notIncluded ? 'Add' : 'Remove',
                                            objType: 'subMenu',
                                            menuId: menu.id,
                                            submenuId: subMenu.id,
                                            item: subMenu,
                                            index: i,
                                            subMenuIndex: j
                                        })"
                                        :class="{'btn-success' : subMenu.notIncluded, 'btn-danger' : !subMenu.notIncluded}"
                                        class="btn w-100"
                                    >
                                        {{ subMenu.notIncluded ? 'Add' : 'Remove' }}
                                    </button>
                                </td>
                            </tr>
                            <template v-for="(page, k) in subMenu.pages" :key="k">
                                <tr v-if="!menu.notIncluded && !subMenu.notIncluded">
                                    <td> </td>
                                    <td>  </td>
                                    <td> {{ page.name }} </td>
                                    <!-- <td > {{page.status}} </td> -->
                                    <td>
                                        <button
                                            @click="onClickAction({
                                                actionType: page.notIncluded ? 'Add' : 'Remove',
                                                objType: 'page',
                                                menuId: menu.id,
                                                submenuId: subMenu.id,
                                                pageId: page.id,
                                                item: page,
                                                index: i,
                                                subMenuIndex: j,
                                                pageIndex: k
                                            })"
                                            :class="{'btn-success' : page.notIncluded, 'btn-danger' : !page.notIncluded}"
                                            class="btn w-100"
                                        >
                                            {{ page.notIncluded ? 'Add' : 'Remove' }}
                                        </button>
                                    </td>
                                </tr>
                            </template>
                        </template>
                    </template>
                </tbody>
            </table>
            <!-- <p v-if="!ledgers.length" class="mt-5 text-center">No data</p> -->
        </div>
    </div>
</template>

<script>

export default {
    name: 'MenuDesignTable',
    props: {
        menus: Object
    },
    methods: {
        onClickAction (data) {
            this.$emit('onClickAction', data)
        }
    }
}
</script>
